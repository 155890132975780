import React, { useState } from "react";
import useScroll from "../hooks/useScroll";
import { Toast, ToastType } from "../types";
import { v4 as uuidv4 } from "uuid";

type AppContextStateType = {
    scrollOffset: number;
    toasts: Toast[];
    // setToasts: React.Dispatch<React.SetStateAction<Toast[]>>;
    addToast: (type: ToastType, message: string) => void;
    removeToast: (id: string) => void;
};

export const AppContext = React.createContext({
    scrollOffset: 0,
    toasts: [],
} as AppContextStateType);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const { offset } = useScroll();
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = (type: ToastType, message: string) => {
        const newToast = {
            id: uuidv4(),
            type,
            message,
        };

        setToasts(prevState => ([
            ...prevState,
            newToast,
        ]));
    };

    const removeToast = (id: string) => {
        setToasts(prevState => ([...prevState.filter(x => x.id !== id)]));
    };

    return <AppContext.Provider value={{ scrollOffset: offset, toasts, addToast, removeToast }}>
        {children}
    </AppContext.Provider>;
};
