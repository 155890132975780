exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adorare-tsx": () => import("./../../../src/pages/adorare.tsx" /* webpackChunkName: "component---src-pages-adorare-tsx" */),
  "component---src-pages-afectiune-tsx": () => import("./../../../src/pages/afectiune.tsx" /* webpackChunkName: "component---src-pages-afectiune-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-krestere-tsx": () => import("./../../../src/pages/krestere.tsx" /* webpackChunkName: "component---src-pages-krestere-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-politica-cookies-tsx": () => import("./../../../src/pages/politica-cookies.tsx" /* webpackChunkName: "component---src-pages-politica-cookies-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-rascumparare-tsx": () => import("./../../../src/pages/rascumparare.tsx" /* webpackChunkName: "component---src-pages-rascumparare-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-under-construction-tsx": () => import("./../../../src/pages/under-construction.tsx" /* webpackChunkName: "component---src-pages-under-construction-tsx" */)
}

